import { ChevronRight } from "@material-ui/icons";
import Link from "next/link";
import { bpMax, styled } from "theme";
import { getUrlPath } from "utils/helpers";
import source from "utils/source";
import Image from "next/image";
import { getSearchEventProps, setSearchEventProps } from "utils/commonUtils";
import { SEARCH_MP_EVENTS_PROPS_NAME } from "modules/candidate/profile/constants/mixpanelConstants";
import clsx from "clsx";

const Card = ({ title, subtitle, image, link, color }) => {
	const handleJobTypeClick = () => {
		const searchEventObj = getSearchEventProps();
		const obj = {
			[SEARCH_MP_EVENTS_PROPS_NAME.SEARCH_SOURCE]: "Home Page",
		};
		const resObj = { ...searchEventObj, ...obj };
		setSearchEventProps(resObj);
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Jobs for Everyone", {
				page: getUrlPath(),
				Source: source,
				jobType: subtitle,
			});
		});
	};

	return (
		<Link href={{ pathname: link, query: { sourcePage: "Home Page" } }} passHref>
			<StyledCard
				onClick={handleJobTypeClick}
				color={color}
				className="relative overflow-hidden rounded-[24px] border p-6 transition-all hover:shadow-400 md:px-6 md:py-[42px]"
			>
				<p className="m-0 text-md text-[#8C8594]">{title}</p>
				<h3 className="mb-0 mt-3 md:mt-8">{subtitle}</h3>

				<p
					style={{ textShadow: "0px 0px 2px #000" }}
					className="stroked bg-text absolute left-[24px] top-[40%] whitespace-nowrap text-[28px] font-bold text-transparent transition-all duration-500 md:left-[75px] md:top-[47%] md:text-[64px]"
				>
					{subtitle}
				</p>

				<div
					className={clsx(
						"absolute bottom-0 right-0 h-full max-h-[130px] w-[100px] object-contain md:max-h-[180px] md:w-[45%]",
						{
							"bottom-[-16px] right-[right-0] md:bottom-[0px] md:right-[0]":
								title === "TRENDING AT #1",
							"bottom-[-6px] md:bottom-[0px]": title === "TRENDING AT #5",
						}
					)}
				>
					<Image
						className="object-contain"
						layout="fill"
						objectFit="contain"
						src={image}
						alt={subtitle}
						loading="lazy"
						quality={50}
					/>
				</div>

				<span className="mt-[64px] inline-block rounded-lg px-4 py-2 font-semibold transition-all md:mt-[100px]">
					View all <ChevronRight fontSize="small" />
				</span>
			</StyledCard>
		</Link>
	);
};

const StyledCard = styled.a`
	display: block;
	${({ color }) => `

    .bg-text {
        // -webkit-text-stroke-width: 1px;
        // -webkit-text-stroke-color: #190A28;
        opacity: 0.3;
        color: #fff;
    }

    background: linear-gradient(145deg,#fff,#00000005);
    
    &:hover {
        background: linear-gradient(180deg, #fff, ${color}24 130%);
			border: 1px solid ${color};
			
			h3 {
				color: ${color};
			}

            span {
                background: ${color};
                color: #fff;
            }

            .bg-text {
                -webkit-text-stroke-color: ${color};
            }

			.stroked {
				left: 24px;
			}
    }

    ${bpMax.lg}  {
        background: linear-gradient(180deg, #fff, ${color}24 130%);
			
			h3 {
				color: ${color};
			}

            span {
                background: ${color};
                color: #fff;
            }

            .bg-text {
                -webkit-text-stroke-color: ${color};
            }
    }
    `}
`;

export const POPULAR_SEARCHES = "popular-searches";
const PopularSearches: React.FC = () => (
	/* Don't remove the id, as floating nudge on homepage is dependent on it */
	<div
		id={POPULAR_SEARCHES}
		className="mx-auto flex max-w-screen-xl grid-cols-3 grid-rows-2 flex-col flex-wrap gap-4 px-4 py-[72px] md:grid md:py-[100px]"
	>
		<div className="mb-[48px] flex items-center md:mb-0">
			<h2 className="mb-0 text-center text-[32px] font-bold leading-[48px] md:text-left md:text-[56px] md:leading-[67px]">
				Popular Searches on Apna
			</h2>
		</div>
		{data.map((i) => (
			<Card {...i} />
		))}
	</div>
);

const data = [
	{
		title: "TRENDING AT #1",
		subtitle: "Jobs for Freshers",
		image: "https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/freshers-jobs.png",
		link: "/jobs/freshers-jobs",
		color: "#DE3700",
	},
	{
		title: "TRENDING AT #2",
		subtitle: "Work from home Jobs",
		image: "https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/work-from-home-jobs.png",
		link: "/jobs/work_from_home-jobs",
		color: "#722ED1",
	},
	{
		title: "TRENDING AT #3",
		subtitle: "Part time Jobs",
		image: "https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/part-time-jobs.png",
		link: "/jobs/part_time-jobs",
		color: "#CC0000",
	},
	{
		title: "TRENDING AT #4",
		subtitle: "Jobs for Women",
		image: "https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/women-jobs.png",
		link: "/jobs/female-jobs",
		color: "#1F8268",
	},
	{
		title: "TRENDING AT #5",
		subtitle: "Full time Jobs",
		image: "https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/Full_time_banner_home_page.png",
		link: "/jobs/full_time-jobs",
		color: "#0074E8",
	},
];

export default PopularSearches;
