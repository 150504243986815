import { NextPage } from "next";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";
import Script from "next/script";
import { getUrlPath } from "utils/helpers";
import TrendingRoles from "components/NewerHomepage/TrendingRoles";
import WantToHire from "components/NewerHomepage/WantToHire";
import DownloadApna from "components/NewerHomepage/DownloadApna";
import NavbarV2 from "components/NavbarV2";
import Footer from "components/NewerHomepage/Footer";
import SEOV2 from "components/seoV2";
import TagManagerUtils from "utils/TagManager";
import PopularSearches from "components/NewerHomepage/PopularSearches";
import { isUserLoggedIn } from "utils";
import dynamic from "next/dynamic";
import Image from "next/image";
import useTrackWebSession from "hooks/useTrackWebSession";
// import { tickerData } from "components/NewerHomepage/TickerData";
import { useWindowResize } from "utils/hooks";
import { useRouter } from "next/router";
import WebToAppNudge from "components/WebToApp";
import Mixpanel from "utils/Mixpanel";
import { GOVT_SUPPORT_LOGOS, TRUSTED_COMPANIES } from "utils/constants";

const Marquee = dynamic(() => import("components/Marquee/Marquee"), {
	ssr: false,
});

const metaTitle = "Search Jobs Online | Hire Candidates | Post a Job";
const metaDescription =
	"Apna is India's leading Professional Networking Platform. Users can Search for Jobs Online & Apply for Free. Employers can Post Job Vacancies & Hire Quality Candidates";
const pageName = "Main Home Page";

const SearchComponent = dynamic(() => import("components/Search/Search"), {
	ssr: false,
});
const SearchComponentMobile = dynamic(() => import("components/Search/SearchMobile"), {
	ssr: false,
});
const JoinTheCommunity = dynamic(
	() => import("components/NewerHomepage/JoinTheCommunity"),
	{
		ssr: false,
	}
);
const PopularCompanies = dynamic(
	() => import("components/NewerHomepage/PopularCompanies/PopularCompanies"),
	{
		ssr: false,
	}
);

const Home: NextPage = () => {
	const router = useRouter();
	const [isMobileView] = useWindowResize();
	useTrackWebSession(pageName);

	const [isShowSearchModal, setShowSearchModal] = useState(false);

	const [isCandidateLoggedIn, setIsCandidateLoggedIn] = useState<boolean>(false);

	useEffect(() => {
		const loggedIn = Boolean(isUserLoggedIn()); // Ensure boolean conversion
		setIsCandidateLoggedIn(loggedIn);
	}, []);

	useEffect(() => {
		Mixpanel.track("Page View", {
			Source: pageName,
			page: getUrlPath(),
		});

		TagManagerUtils.addDataLayer({
			Source: pageName,
		});
	}, []);

	// This helps in closing the FilterSideBar Component on click of browser's back button
	useEffect(() => {
		const handlePopState = ({ as }: { as: string }) => {
			if (as !== router.asPath && isShowSearchModal) {
				setShowSearchModal(false);
			}
			return true;
		};

		router.beforePopState(handlePopState);

		return () => {
			router.beforePopState(() => true);
		};
	}, [isShowSearchModal]);

	useEffect(() => {
		if (isShowSearchModal) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isShowSearchModal]);

	return (
		<ThemeProvider theme={theme.apna}>
			<SEOV2
				title={metaTitle}
				description={metaDescription}
				metaTitle={metaTitle}
				image={undefined}
				pageType={undefined}
				publishedAt={undefined}
				slug={undefined}
			/>

			{/* Header */}
			<NavbarV2 containerClassName="!max-w-screen-xl !px-4" fromHomepage />
			{/* Hero section */}
			<div
				style={{
					height: isMobileView ? "auto" : "100vh",
				}}
				className="relative flex max-h-[1180px] flex-col overflow-hidden bg-surface-disabled pb-12 md:min-h-[768px] md:py-10 md:pt-[20px]"
			>
				<div className="absolute bottom-0 left-[30%] z-[2] hidden w-full translate-x-[-30%] xs:right-0 md:bottom-0 md:left-[unset] md:right-[-80px] md:block md:max-w-[820px] md:translate-x-[unset] lg:w-[48%]">
					<Image
						layout="responsive"
						width={820}
						height={598}
						objectFit="contain"
						objectPosition="bottom"
						className="relative w-full  md:ml-auto"
						src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/HomePageCreative.png"
						alt="hero"
						priority
					/>
				</div>
				<div
					style={{
						top: "143px",
						width: "520px",
						height: "420px",
						left: "-200px",
						opacity: 0.6,
						position: "absolute",
						background:
							"radial-gradient(circle at center center, rgba(183, 90, 255, 0.12) 29.6%, rgb(21 193 217 / 0%) 66.76%)",
					}}
				/>
				<div
					className="hidden md:block"
					style={{
						zIndex: 1,
						top: "220px",
						width: "720px",
						height: "420px",
						right: "-100px",
						opacity: 1,
						position: "absolute",
						background:
							"radial-gradient(#156fd914 29.6%, rgba(21, 193, 217, 0) 65%)",
					}}
				/>

				{/* Content */}
				<div className="relative z-10 mx-auto flex h-full w-full max-w-screen-xl flex-col px-5 md:mb-[150px] md:block md:h-auto">
					<h1 className="mb-0 mt-6 text-[20px] font-bold text-green-500 md:mt-10">
						INDIA’S #1 JOB PLATFORM
					</h1>
					<h2 className="mb-[24px] mt-3 max-w-[300px] text-[32px] font-bold leading-[40px] md:mt-6 md:max-w-none md:text-[56px] md:leading-[67px]">
						Your job search ends here
					</h2>
					<h2 className="m-0 mb-[20px] mt-1 w-[180px] text-md font-[400] md:mb-[55px] md:mt-4 md:w-full md:text-[24px]">
						Discover 50 lakh+ career opportunities
					</h2>

					{/* Search section */}
					<div className="relative">
						{isMobileView ? (
							<SearchComponentMobile
								fromHomePage
								isShowSearchModal={isShowSearchModal}
								setShowSearchModal={setShowSearchModal}
							/>
						) : (
							<SearchComponent fromHomePage />
						)}
						<div className="absolute right-0 top-[-120px] block md:hidden">
							<Image
								width={120}
								height={120}
								objectFit="contain"
								objectPosition="bottom"
								className="relative w-full  md:ml-auto"
								src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/HomePageCreative.png"
								alt="hero"
								priority
							/>
						</div>
					</div>

					<div className="mt-[32px] md:mt-[80px]">
						<h1 className="text-center text-[14px] font-[600] leading-[20px] text-[#172B4D] md:text-start md:text-[20px] md:leading-[28px] md:text-[#190A28]">
							Proud to Support
						</h1>

						<div className="mt-[12px] flex flex-wrap items-center justify-center gap-[24px] md:mt-[16px] md:flex-nowrap md:justify-normal md:gap-[40px]">
							{GOVT_SUPPORT_LOGOS.map((item) => (
								// eslint-disable-next-line @next/next/no-img-element
								<img
									key={item.id}
									src={item.url}
									alt="Support Icon"
									className="h-[32px] object-contain md:h-[40px]"
								/>
							))}
						</div>
					</div>

					{/* Carousel */}
					<div className="z-50 mt-[44px] md:mt-[68px] md:max-w-[70%]">
						<h1 className="text-center text-[14px] font-[600] leading-[20px] text-[#172B4D] md:text-start md:text-[20px] md:leading-[28px] md:text-[#190A28]">
							Trusted by 1000+ enterprises and 7 lakh+ MSMEs for hiring
						</h1>
						<Marquee
							stopOnHover
							speed={50}
							className="relative mt-[12px] gap-[32px] md:mt-[24px] md:gap-[56px]"
						>
							<div className="flex gap-[32px] md:gap-[56px]">
								{TRUSTED_COMPANIES.map((item) => {
									const Icon = item.image;

									return item?.link ? (
										// eslint-disable-next-line @next/next/no-img-element
										<img
											key={item.key}
											src={item.link}
											height={32}
											className="h-[32px]"
											alt="Company Logo"
										/>
									) : (
										<Icon
											key={item.key}
											className="h-[32px] object-contain"
										/>
									);
								})}
							</div>
						</Marquee>
					</div>
				</div>
			</div>

			<PopularSearches />

			{/* Openings in popular companies */}
			{!isCandidateLoggedIn && <PopularCompanies />}

			{/* Trending roles */}
			{!isCandidateLoggedIn && <TrendingRoles />}

			{/* Join the community */}
			<JoinTheCommunity />

			{/* Download app */}
			<DownloadApna />

			{/* Want to hire */}
			{!isCandidateLoggedIn && <WantToHire />}

			{/* Footer */}
			<div className="mb-[66px] md:mb-0">
				<Footer />
			</div>
			<WebToAppNudge />
			<Script
				id="organization"
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `
								{
								"@context": "https://schema.org",
								"@type": "Organization",
								"name": "ApnaTime",
								"url": "https://apna.co/",
								"logo": "https://apna.co/apna-time-icon.png",
								"sameAs": [
									"https://www.facebook.com/APNA-2386268451700180",
									"https://www.linkedin.com/company/26594389/admin/", 
									"https://twitter.com/apnahq",
									"https://www.instagram.com/apnahq/", 
									"https://www.youtube.com/channel/UCl-retoBiPxEqXMxxIhBt7A",
									"http://m.helo-app.com/al/FZxZFrjxr",
									"http://tiktok.com/@APNAJOBS",
									"https://sharechat.com/profile/apnajob"
								]
								}
         					 `,
				}}
			/>
			<Script
				id="software-application"
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `
						{
							"@context": "https://schema.org",
							"@type": "SoftwareApplication",
							"name": "Apna",
							"operatingSystem": "ANDROID",
							"applicationCategory": "BusinessApplication",
							"aggregateRating": {
								"@type": "AggregateRating",
								"ratingValue": "4.3",
								"ratingCount": "43051"
							},
							"offers": {
								"@type": "Offer",
                                "price": "0",
                                "priceCurrency": "INR"
							}
						}
					`,
				}}
			/>
		</ThemeProvider>
	);
};

export default Home;
